<template>
  <article>
    <div class="q-pa-xl">
      <div class="search-container">
        <q-input 
          v-model="inputDate" 
          type="date" 
          label="날짜 선택" 
          @change="handleDateChange" 
          dense 
          outlined
        />
      </div>
      <div v-if="list.length == 0" class="q-mt-xl">
        조회된 내용이 없습니다.
      </div>
      <div v-if="list.length > 0" class="q-mt-xl">
        <q-markup-table class="markUpTb">
          <thead>
            <q-tr>
              <q-th class="selectable">종목</q-th>
              <q-th class="selectable">등락률</q-th>
              <q-th class="selectable">사유</q-th>
            </q-tr>
          </thead>
          <tbody>
            <q-tr v-for="item in sortedList" :key="item.pid">
              <q-td>{{ item.stockName }}</q-td>
              <q-td>
                {{ item.changeRate >= 0 ? '+' : '' }}{{ (item.changeRate * 0.01).toFixed(2) }}%
              </q-td>
              <q-td>{{ item.content }}</q-td>
            </q-tr>
          </tbody>
        </q-markup-table>
      </div>
    </div>
  </article>
</template>

<script>
import api from '@/services/InfostockService'

export default {
  name: "StockJump",
  data() {
    return {
      list: [],
      inputDate: this.formatInputDate(new Date()), // 초기 날짜 설정 (yyyy-MM-dd)
      searchDate: this.formatDate(new Date()), // yyyyMMdd 형식으로 설정
    }
  },
  computed: {
    sortedList() {
      return this.list.slice().sort((a, b) => b.changeRate - a.changeRate)
    }
  },
  created() {
    this.StockJumpList()
  },
  methods: {
    async StockJumpList() {
      try {
        const params = { date: this.searchDate }
        const data = await api.stockJumpList(params)
        this.list = data.data.items
        console.log('급등주 리스트', this.list)
      } catch (err) {
        console.error('Error fetching stock jump list:', err)
      }
    },
    formatDate(date) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join(''); // yyyyMMdd 형식으로 반환
    },
    formatInputDate(date) {
      const d = new Date(date);
      let month = '' + (d.getMonth() + 1);
      let day = '' + d.getDate();
      const year = d.getFullYear();

      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      return [year, month, day].join('-'); // yyyy-MM-dd 형식으로 반환
    },
    handleDateChange() {
      this.searchDate = this.formatDate(this.inputDate); // yyyyMMdd 형식으로 변환
      this.StockJumpList(); // API 호출
    }
  },
  watch: {
    inputDate(newDate) {
      this.searchDate = this.formatDate(newDate); // yyyyMMdd 형식으로 변환
    }
  }
}
</script>

<style scoped>
.search-container {
  max-width: 200px;
  margin-bottom: 20px;
}
.markUpTb .q-table thead {
  background-color: grey;
  color: white;
}
.markUpTb .q-table thead tr, .q-table tbody td {
  height: 30px;
  border-left: 1px solid rgba(0,0,0,0.12);
}
.selectable {
  user-select: text; /* 텍스트 선택을 허용 */
}
</style>