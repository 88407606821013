<template>
    <q-input
        ref="qInput"
        dense
        :outlined="!disable"
        :standout="disable ? 'bg-teal text-white' : false"
        :value="pvalue"
        @input="myChange"
        @keydown="preventSemicolon"
        :label="label"
        :disable="disable"
        :type="type"
        :rows="rows"
        @focus="focus()"
        :autogrow="grow"
        :readonly="readonly"
    >
        <template v-if="inputLabel" v-slot:before>
            <label class="i-label">{{ inputLabel }}</label>
        </template>
    </q-input>
</template>

<script>
export default {
    name: 'ExceptionInput',
    props: {
        value: {
            required: true,
        },
        label: String,
        disable: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        type: {
            type: String,
            default: 'text',
        },
        rows: {
            type: Number,
            default: 0,
        },
        inputLabel: String,
    },
    data() {
        return {
            pvalue: this.value, // 내부 데이터로 관리
        };
    },
    computed: {
        grow() {
            return (
                this.type === 'textarea' &&
                this.rows === 0 &&
                this.pvalue !== null &&
                this.pvalue !== undefined &&
                this.pvalue.length > 0
            );
        },
    },
    watch: {
        value(newValue) {
            // 부모로부터 변경된 값 반영
            this.pvalue = newValue;
        },
    },
    methods: {
        myChange(val) {
            this.pvalue = val; // 유효한 입력값 갱신
            this.$emit('input', val); // 부모 컴포넌트에 값 전달
        },
        preventSemicolon(event) {
            if (event.key === ";") {
                event.preventDefault(); // 키 입력 자체를 막음
                this.$q.notify({
                    message: ';은 입력할 수 없습니다.',
                    color: "negative",
                    position: "top",
                    timeout: 3000,
                });
            }
        },
        focus() {
            this.$emit('focus');
        },
    },
};
</script>
