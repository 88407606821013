<template>
  <article>
      <q-toolbar class="q-ma-xs">
          <q-input
              v-show="!isFlashPage"
              class="full-width"
              dense
              outlined
              v-model="originalData"
              @keyup="handler"
              type="textarea"
              style="height:52px"
              placeholder="Excel Header를 제외한 Data를 복사한 후 붙여넣기 하세요."
          />
          <q-space />
          <i-btn label="취소" @click="cancelInput()" />
      </q-toolbar>
      <q-scroll-area visible class="i-content-wrap">
          <!-- 리스트 화면 / 등록 화면 -->
          <div v-if="excelData.length > 0 && !isFlashPage">
              <i-datetime
                  v-if="dateInput"
                  class="col-6 q-pa-xs"
                  v-model="insertDate"
                  date
                  dateLabel="입력일"
              />
              <q-markup-table flat bordered dense>
                  <thead>
                      <tr>
                          <th class="text-left">종목코드</th>
                          <th class="text-left">종목</th>
                          <th class="text-left">내용</th>
                          <th
                              v-if="isUsa"
                              class="text-left"
                          >
                              {{ optionLabel }}
                          </th>
                          <th v-if="excelData.length > 0">
                              <q-icon
                                  name="remove_circle_outline"
                                  size="xs"
                                  @click="clear()"
                              />
                          </th>
                      </tr>
                  </thead>
                  <tbody v-if="excelData.length > 0">
                    <tr
                        v-for="(item, index) in excelData"
                        :key="'excelData-' + index"
                        :class="item.valid ? 'bg-white-3' : 'bg-red-3'"
                    >
                        <!-- 종목코드 -->
                        <td class="text-left">
                        <q-input
                            v-model="item.stockCode"
                            dense
                            outlined
                            :disable="item.valid"
                        />
                        </td>

                        <!-- 종목명 -->
                        <td class="text-left">
                        <q-input
                            v-model="item.stockName"
                            dense
                            outlined
                            :disable="item.valid"
                        />
                        </td>

                        <!-- 내용 -->
                        <td class="text-left">
                        <q-input
                            v-model="item.content"
                            dense
                        />
                        </td>

                        <!-- 옵션 -->
                        <td
                        v-if="isUsa"
                        class="text-left"
                        >
                        <q-input
                            v-model="item.option"
                            dense
                        />
                        </td>

                        <!-- 삭제 버튼 -->
                        <td>
                        <q-icon
                            name="remove_circle_outline"
                            size="xs"
                            @click="remove(index)"
                        />
                        </td>
                    </tr>
                    </tbody>
                </q-markup-table>
                </div>
          <div v-else>
              <q-card flat bordered>
                  <!-- 상세 화면 / 수정 화면 -->
                  <q-card-section v-show="!isFlashPage">
                      <div class="row">
                          <div
                              class="col-6 q-pa-xs"
                              v-show="isRegisterPage || isListPage"
                          >
                              <!-- <i-stock-search
                                  @get="getStock"
                                  label="종목검색"
                              /> -->
                          </div>
                      </div>
                          <!-- 220128 meej 입력일 조건 삭제 -> v-if="dateInput" -->
                      <div class="row">
                          <i-datetime
                              class="col-6 q-pa-xs"
                              v-model="insertDate"
                              date
                              dateLabel="입력일"
                          />
                          <i-datetime
                              class="col-6 q-pa-xs"
                              v-model="updateDate"
                              date
                              :disable="true"
                              dateLabel="수정일"
                          />
                      </div>

                      <div class="row">
                          <div class="col-6 q-pa-xs">
                              <i-field
                                  :text="stockNews.stockName"
                                  inputLabel="종목명"
                              />
                          </div>
                          <div class="col-6 q-pa-xs">
                              <i-field
                                  :text="stockNews.stockCode"
                                  inputLabel="종목코드"
                              />
                          </div>
                      </div>

                      <div class="q-pa-xs">
                          <i-input
                              type="textarea"
                              v-model="stockNews.content"
                              inputLabel="사유"
                              :rows="10"
                          />
                      </div>
                  </q-card-section>
              </q-card>
          </div>
      </q-scroll-area>
      <div>
          <i-btn
              v-show="isListPage"
              class="full-width"
              label="등록"
              @click="write()"
              :disable="excelData.length == 0"
          />
          <i-btn
              v-show="isRegisterPage"
              class="full-width"
              label="등록"
              @click="write()"
          />
          <i-btn
              v-show="isDetailPage"
              class="full-width"
              label="수정"
              @click="write()"
          />
          <!-- <i-btn v-show="isFlashPage" class="full-width" label="전송" /> -->
      </div>
  </article>
</template>
<script>
import Debug from '@/debug'
import { objToString, clone, today } from '@/util'

import Button from '@/components/common/Button'
import Input from '@/components/common/Input'
import Field from '@/components/common/Field'
import DateTime from '@/components/common/DateTime'
// import StockSearch from '@/components/common/StockSearch'
// import Preview from '@/components/common/Preview'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import debug from '../../../debug'

export default {
  name: 'UsaContent',
  components: {
      'i-btn': Button,
      'i-datetime': DateTime,
      'i-input': Input,
      'i-field': Field,
    //   'i-stock-search': StockSearch,
      // 'i-preview': Preview,
  },
  props: {
      //news: Object,
      newsType: {
          type: Object,
          default: null,
      },
  },
  data() {
      return {
          insertDate: '',
          updateDate: '',
          excelData: [],
          originalData: null,
          sendTime: '',
          newsFlashData: {},
          stockNews: {
              stockName: '',
              stockCode: '',
              content: '',
          },
          textColor: 'black',
          isExcel: false,
      }
  },
  created() {
      this.insertDate = today()
      this.updateDate = today()
      Debug.log('create insertDate', this.insertDate)
  },
  computed: {
      ...mapState({
          selectItem: (state) => state.usa_stock_news.item,
          PAGE_STATUS: (state) => state.page.status,
          pageStatus: (state) => state.page.pageStatus,
          stockItems: (state) => state.resource.stockItems,
      }),
      ...mapState('flash', ['preview']),
      ...mapGetters('page', [
          'isListPage',
          'isDetailPage',
          'isRegisterPage',
          'isFlashPage',
      ]),

      isUsa() {
          if (this.newsType) {
              return this.newsType.value == 'USA_STOCK_NEWS'
          } else {
              return false
          }
      },
      optionLabel: function() {
          var label = ''
          if (this.newsType.value == 'USA_STOCK_NEWS') {
              label = '등락률'
          }
          return label
      },
      // 20210712 shb
      dynaColumns() {
          const columns = [
              { label: '종목', value: 'stockCode' },
              //  20210709 shb
          ]
          if (
              this.newsType.value === 'STOCK_ISSUE' ||
              this.newsType.value === 'STOCK_52_WEEKS_NEW_HIGH'
          ) {
              columns.push({ label: '내용', value: 'content' })
          } else {
              columns.push({ label: '옵션', value: 'option' })
              columns.push({ label: '내용', value: 'content' })
          }

          return columns
      },

      // 20210804 shb 입력일 보여주는곳에서만 보일수 있도록
      dateInput() {
          if (
              this.newsType == null ||
              this.newsType.value == 'STOCK_OVERTIME_UNUSUAL' || this.newsType.value == 'USA_STOCK_NEWS' ||
              this.newsType.value == 'MARKET_OVERTIME_COMMENT'
          ) {
              return false
          }
          return true
      },
  },
  methods: {
      ...mapMutations('page', ['setPageStatus']),
      ...mapMutations('usa_stock_news', ['USA_INIT_LIST']),

      ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
      ...mapActions('usa_stock_news', [
          'USA_ASYNC_LIST',
          'USA_EXCEL_WRITE_ITEM',
          'USA_WRITE_ITEM',
      ]),
      cancelInput() {
          this.initItem()
          this.goListPage()
          this.$emit('event-refresh-list')
      },
      initItem() {
          this.insertDate = today()
          this.updateDate = today()
          this.excelData = []
          this.stockNews.stockName = ''
          this.stockNews.stockCode = ''
          this.stockNews.content = ''
      },
      displayStock: function(item) {
          console.log(`${item.stockName}(${item.stockCode})`)
          return item.valid
              ? item.stockName + '(' + item.stockCode + ')'
              : item.stockCode
      },
      // 목록화면으로 이동하기
      goListPage() {
          this.excelData = []
          this.setPageStatus(this.PAGE_STATUS.LIST)
      },
      // 목록 조회
      getList(param) {
          var params = {
              newsType: this.newsType.value,
          }
          if (param) {
              params.code = param.stockCode
          }

          this.USA_ASYNC_LIST(params)
      },
      write() {
          if (this.isExcel) {
              this.writeExcel()
          } else {
              this.writeItem()
          }
      },
      // 등록하기
    writeExcel() {
        // 뉴스타입 체크
        if (this.newsType.value === 'STOCK_ALL' || this.newsType === null) {
            this.showMessage({
            vc: this,
            message: '뉴스타입을 확인해주세요.',
            });
            return;
        }
        // 빈값 체크
        for (var item of this.excelData) {
            if (!item.stockCode) {
            this.showMessage({
                vc: this,
                message: '엑셀 내용을 확인해주세요.',
            });
            return;
            }
        }
        // 종목코드 유효체크
        for (let i = 0; i < this.excelData.length; i++) {
            let row = this.excelData[i];

            // 종목명은 유효성 체크 없이 입력된 값을 그대로 사용
            this.excelData[i].stockName = row.stockName;
        }

        this.showAlert({ vc: this, message: '등록하시겠습니까?' }).then(() => {
            this.doWriteExcelItem();
        });
    },

      // 엑셀 입력 API
      doWriteExcelItem() {
          let items = this.excelData
          let list = []

          items.forEach((row) => {
              let obj = {}
              obj.stockCode = row.stockCode
              obj.stockName = row.stockName
              obj.content = row.content

              let temp = {}
              if (this.isDaysNewsType) {
                  temp.DAYS = row.option
              } else if (this.isTradingMarketNewsType) {
                  temp.TRADING_MARKET = row.option
              }
              obj.options = objToString(temp)
              if (this.isOvertime || this.isUsa) {
                  const rate = parseFloat(row.option) * 100

                  obj.changeRate = rate
              }
              list.push(obj)
          })

          var params = {
              newsType: this.newsType.value,
              stockNews: list,
          }

          params.showDate = this.insertDate
          console.log("params Items: ", params);
          this.USA_EXCEL_WRITE_ITEM(params)
              .then(() => {
                  this.showMessage({ vc: this, message: '등록되었습니다.' })

                  this.clear()
                  this.USA_INIT_LIST()
                  this.getList()
                  this.goListPage()
              })
              .catch((error) => {
                  Debug.log('error = ', error)
                  this.showError({ vc: this, error: error })
              })
      },
      // 종목뉴스 수정하기
      writeItem() {
          // 20210802 shb flag 추가 - 수정,신규등록 을 판단
          let news = this.stockNews

          let params = {}

          if (!news.stockCode) {
              this.showMessage({
                  vc: this,
                  message: '종목코드를 입력해주세요.',
              })
              return
          }
          var options = {}
          if (this.isDaysNewsType) {
              if (!news.DAYS) {
                  options.DAYS = ''
              } else {
                  options.DAYS = news.DAYS
              }

              params.options = objToString(options)
          }

          if (this.isTradingMarketNewsType) {
              if (!news.TRADING_MARKET) {
                  this.showMessage({
                      vc: this,
                      message: '거래시장을 입력해주세요.',
                  })
                  return
              }
              options.TRADING_MARKET = news.TRADING_MARKET
              params.options = objToString(options)
          }
          if (this.isDetailPage) {
              params.id = news.id
              params.newsType = news.newsType1
          } else {
              params.newsType = this.newsType.value
          }

          if (
              params.newsType === null ||
              params.newsType === undefined ||
              params.newsType === 'STOCK_ALL'
          ) {
              this.showMessage({ vc: this, message: '뉴스를 확인해주세요.' })
              return
          }

        //   var temp = this.stockItems.find(
        //       (element) => element.code == news.stockCode
        //   )
          params.stockCode = news.code

          params.title = news.title
          params.content = news.content
          // 20210802 shb 입력일 입력 가능하도록 요청 사항 으로 추가
          // 수정에도 입력날짜 바뀌게 변경
          params.showDate = this.insertDate

          let mesg = this.isDetailPage
              ? '수정하시겠습니까?'
              : '등록하시겠습니까?'
          this.showAlert({ vc: this, message: mesg }).then(() => {
              this.doWriteStockNews(params)
          })
      },

      // 종목뉴스 수정 API
      doWriteStockNews(params) {
          this.USA_WRITE_ITEM(params)
              .then(() => {
                  let mesg = this.isDetailPage
                      ? '수정되었습니다'
                      : '등록되었습니다.'
                  this.showMessage({ vc: this, message: mesg })
                  this.USA_INIT_LIST()
                  if (this.isDetailPage) {
                      this.getList(params)
                  } else {
                      this.getList()
                  }
                  this.clear()
                  this.goListPage()
              })
              .catch((error) => {
                  Debug.log('error = ', error)
                  this.showError({ vc: this, error: error })
              })
      },
      getStock(stock) {
          this.setPageStatus(this.PAGE_STATUS.REGISTER)
          this.stockNews.stockName = stock.name
          this.stockNews.stockCode = stock.code
      },
      // 엑셀 데이터 관련 함수
      handler(e) {
          this.showMessage({ vc: this, message: '신규 데이터가 입력됩니다.' })
          if (this.pageStatus === this.PAGE_STATUS.DETAIL) {
              this.setPageStatus(this.PAGE_STATUS.REGISTER)
          }
          this.isExcel = true
          if (this.originalData === '') return
          if (e.code == 'KeyV' || e.code == 'MetaLeft') {
              this.excelData = []
              this.convertToJson(this.originalData)
              this.originalData = ''
          }

          Debug.log('handle excelData ', this.excelData)
      },

      convertToJson(str) {
          const reg = new RegExp('.*\\(|\\)', 'g')
          const colon = new RegExp(':')
          const newLine = new RegExp('\\n')
          let rowList
          if (str !== null) {
              if (str.search(newLine) > 0) {
                  rowList = str.split('\n')
              } else {
                  rowList = [str]
              }
          } else {
              debug.log('str null')
          }
          let convRow = []
          let json = ''
          // const dash = new RegExp('-')
          const newsType = this.newsType.value
          rowList.forEach((row) => {
              if (row.length === 0) return
              switch (newsType) {
                  case 'STOCK_ISSUE':
                      json = this.issueParser(row, colon, reg)
                      break
                  case 'STOCK_52_WEEKS_NEW_HIGH':
                      convRow = row.split('\t')
                      json = this.weeksHighParser(convRow)
                      break
                  case 'STOCK_JUMP':
                  case 'STOCK_SLUMP':
                      convRow = row.split('\t')
                      json = this.jumpSlumpParser(convRow)
                      break
                  default:
                      convRow = row.split('\t')
                      Debug.log('convertToJson row = ', row)
                      json = this.generateJson(convRow)
              }
              Debug.log('convertToJson json = ', json)
              this.excelData.push(json)
          })
      },
      issueParser(row, Separator, reg) {
          const convRow = row.split(Separator)
          // 괄호안 문자만 추출
          convRow[0] = convRow[0].replace(reg, '').trim()
          // convRow[0] = stock
          Debug.log(convRow)
          return this.generateJson(convRow)
      },
      weeksHighParser(row) {
          // 종목명 삭제
          row.splice(1, 1)
          // const stock = convRow[0].trim()
          return this.generateJson(row)
      },
      jumpSlumpParser(row) {
          return this.generateJson(row)
      },

    generateJson(row) {
        let json = {};

        // 종목코드와 종목명 추출
        let stockCode = row[0]?.trim(); // QUBT
        let stockName = row[1]?.trim(); // 퀀텀 컴퓨팅
        let changeRate = row[2]?.trim(); // 등락률 -3.08
        let content = row.slice(3).join(' ').trim(); // 나머지 내용을 합침

        // JSON 구성
        json['valid'] = true; // 항상 유효 처리
        json['stockCode'] = stockCode; // 종목코드
        json['stockName'] = stockName; // 종목명
        json['option'] = changeRate; // 등락률
        json['content'] = content; // 내용

        console.log("Generated JSON: ", json);
        return json;
    },

      clear() {
          this.excelData = []
      },
      remove(index) {
          this.excelData = [
              ...this.excelData.slice(0, index),
              ...this.excelData.slice(index + 1),
          ]
      },
  },
  watch: {
      newsType(val, old) {
          if (val != old) {
              this.isExcel = false
              this.initItem()

              //this.getList()
          }
      },
      selectItem(val, old) {
          Debug.log('watch selectItem val=', val, ', old = ', old)
          if (val !== null && val !== old) {
              this.isExcel = false
              this.setPageStatus(this.PAGE_STATUS.DETAIL)
              this.stockNews = clone(val)
              this.insertDate = this.stockNews.showDate
              this.updateDate = this.stockNews.lastUpdateTime
          }
      },
      pageStatus(val) {
          Debug.log('watch pageStatus val=', val)
          if (val === this.PAGE_STATUS.LIST) {
              this.isExcel = false
              this.initItem()
          }
      },
  },
}
</script>
