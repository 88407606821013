import api from '../../../services/InfostockService'
import Debug  from '../../../debug'

const state = () => ({
    data:[],
    currentGubun: null
})

const mutations = {
    SET_CONSEN(state, {data} ) {
        state.data = data
    },    
    SET_CURRENT_GUBUN(state, gubun) {
        state.currentGubun = gubun
    }
}

const actions = {

    // async SETTING_LIST({ commit }, params) {
    //     const {data} = await api.earningPreview(params)
    //     commit('SET_CONSEN', {data} )
    // },  
    async SETTING_LIST({ commit, state }, params) {
        commit('SET_CURRENT_GUBUN', params.gubun)
        const { data } = await api.earningPreview(params)
        if (state.currentGubun === params.gubun) {
          commit('SET_CONSEN', { data })
          console.log("=== commit OK, gubun:", params.gubun)
        } else {
          console.log("=== 늦게 도착한 이전 탭 응답 -> commit 스킵: ", params.gubun)
        }
    },
    async DELETE_PAPER({ commit }, params) {
        const {data} = await api.earningPaperDelete(params)
        commit('SET_CONSEN', {data} )
    },   
    async DELETE_EARNING({ commit }, params) {
        const {data} = await api.earningDelete(params)
        commit('SET_CONSEN', {data} )
    },         
    async NES_RESERVE(_, params) {
        Debug.log('NES_RESERVE params', params)
        const { data } = await api.earningReserve(params)
        Debug.log('NES_RESERVE data', data)
    
    }
}

const getters = {

}

export const preview = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
