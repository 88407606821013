<template>
    <article>
        <!-- 구분 -->
        <div class="row i-top-wrap">
            <div class=" row col-12 ">
                <q-input
                class="col-sm-4 sn-date q-pa-xs"
                outlined
                type="date"
                dense
                v-model="showTime"
                @input="handleShowTimeChange"
                >
                    <template v-slot:before>
                        <label v-if="dateLabel" class="i-label">
                            {{ dateLabel }}
                        </label>
                    </template>
                </q-input>
  
                <div class="col-12 col-sm-4 q-pa-xs">
                    <i-stock-search @search="handleSearch" />
                </div>
                <div class="col-12 col-sm-4 q-pa-xs">
                    <div class="flex justify-end q-gutter-xs">
                        <i-btn label="삭제" @click="deleteItems()" />
                    </div>
                </div>
            </div>
        </div>
        <i-table
            :columns="columns"
            :list="list"
            :usePagination="usePagination"
            :nextKey="nextKey"
            @row-click="getItem"
            @next-click="searchList()"
            @check-event="handleCheckList"
        />
    </article>
  </template>
  <script>
  import Debug from '@/debug'
  import { FormatDate, CutDownText } from '@/util'
  
  import Button from '@/components/common/Button'
  import StockSearch from '@/components/common/UsaStockSearch'
  import Table from '@/components/common/USATable'
  
  import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
  
  export default {
    name: 'UsaStockNewsList',
    components: {
        'i-btn': Button,
        'i-stock-search': StockSearch,
        'i-table': Table,
  
    },
    props: {
        newsType: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            showTime: null,
            stock: '',
            selected: [],
            createSearch: false,
            dateLabel: '',
            columns: [
                {
                    name: 'showDate',
                    label: '입력일자',
                    align: 'left',
                    field: (row) => FormatDate(row.showDate),
                },
                {
                    name: 'lastUpdateTime',
                    label: '수정일자',
                    align: 'left',
                    field: (row) => FormatDate(row.lastUpdateTime),
                },
                //20210726 shb 추가
                {
                    name: 'writer',
                    label: '작성자',
                    align: 'left',
                    field: (row) => row.writer,
                },
                {
                    name: 'stockCode',
                    label: '종목',
                    align: 'left',
                    field: (row) => `${row.stockName} ( ${row.stockCode} )`,
                },
                {
                    name: 'changeRate',
                    label: '등락률',
                    align: 'left',
                    field: (row) => {
                        // 등락률에 0.01을 곱하고 '%'를 붙임
                        const changeRate = row.changeRate * 0.01;
                        return `${changeRate.toFixed(2)}%`;
                    },
                },
                //==============
                {
                    name: 'content',
                    label: '내용',
                    align: 'left',
                    field: (row) => CutDownText(row.content, 30),
                },
            ],
            usePagination: true, // 초기값은 true
        }
    },
    created() {
        this.USA_INIT_LIST()
        this.getList()
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
        }),
        ...mapState('usa_stock_news', ['list', 'nextKey']),
        ...mapGetters('page', [
            'isListPage',
            'isDetailPage',
            'isEditPage',
            'isRegisterPage',
            'isFlashPage',
        ]),
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('usa_stock_news', [
            'USA_INIT_LIST',
            'USA_SET_DETAIL',
            'USA_INIT_DETAIL',
        ]),
  
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('flash', ['PREVIEW_ITEM']),
        ...mapActions('usa_stock_news', [
            'USA_ASYNC_LIST',
            'USA_ASYNC_DATE_LIST',
            'USA_DELETE_ITEMS',
            'USA_ASYNC_SEARCH_LIST',
        ]),

        async handleShowTimeChange() {
            this.usePagination = false;
            await this.USA_INIT_LIST(); // 상태 초기화
            this.searchList(); // 초기화 후 검색 수행
        },
  
        handleCheckList(list) {
            this.selected = list
        },
        // 종목 가져오기 + 목록 조회
        getStock(stock) {
            this.USA_ASYNC_LIST()
  
            var params = {
                code: stock.code,
            }
  
            this.getDateList(params)
        },
        // 날짜별 목록 조회하기
        getDateList(params) {
            if (this.createSearch) {
                if (params == null) {
                    params = {}
                }
                // 220708 meej 날짜검색 오류 수정, replaceAll 추가
                params.date = this.showTime.replaceAll("-","")
                params.newsType = this.newsType.value
  
                console.log("params :  ", params)
  
                this.USA_ASYNC_DATE_LIST(params)
            } else {
                this.createSearch = true
            }
        },
        // 220708 meej 페이징 오류 수정 및 목록 조회 오류 수정
        //              showTime에 따라 목록 조회 선택
        searchList(){
            if(this.showTime != null && this.showTime != ''){
                this.getDateList()
            }else{
                this.getList()
            }
        },
        handleSearch({ type, keyword }) {
            console.log(`검색 타입: ${type}, 검색어: ${keyword}`);
            const params = {
                searchType: type,
                keyword,
                newsType: this.newsType.value,
            };
            this.USA_ASYNC_SEARCH_LIST({ params });
            this.USA_INIT_LIST()
            this.USA_INIT_DETAIL()
        },
        // 목록 조회하기
        getList() {
            var params = {}
            params.newsType = this.newsType.value
            this.USA_ASYNC_LIST(params)
        },
        // 220711 날짜 검색 초기화
        initShowTime(){
            this.showTime = ''
        },
        // 상세 조회하기
        getItem(item) {
            Debug.log('getItem item = ', item)
            this.USA_SET_DETAIL(item)
        },
        // 삭제 알림
        deleteItems() {
            if (this.selected.length == 0) {
                this.showMessage({
                    vc: this,
                    message: '삭제할 항목을 선택해주세요.',
                })
            } else {
                this.showAlert({
                    vc: this,
                    message: '선택한 항목을 삭제하시겠습니까?',
                }).then(() => {
                    this.doDelete()
                })
            }
        },
        // 삭제하기
        doDelete() {
            var params = {}
            params.ids = this.selected.map((x) => x.id).join(',')
            //공시, 이슈, 상한가, 하한가, 급등락, 52주, 시간외 단일가는 Update
            params.force = 0
            this.USA_DELETE_ITEMS(params)
                .then(() => {
                    this.showMessage({ vc: this, message: '삭제되었습니다.' })
                    this.USA_INIT_LIST()
                    this.USA_INIT_DETAIL()
                    // 220711 meej getDateList -> searchList 수정
                    // this.getDateList()
                    this.searchList()
                })
                .catch((err) => {
                    var error = err.message
                    this.showError({ vc: this, error: error })
                    Debug.log('error = ', error)
                })
        },
    },
    watch: {
        showTime(newVal) {
            if (newVal) {
            this.searchList();
            }
        },
    },
  }
  </script>
  