<template>
  <section>
      <div class="row">
          <div class="col-6 q-pa-md">
              <i-stock-list
                  :news-type="typeOptions[0]"
                  v-if="radioType === 'USA_STOCK_NEWS'"
              />
          </div>
          <div class="col-6 q-pa-md">
              <i-stock-content
                  :news-type="typeOptions[0]"
                  v-if="radioType === 'USA_STOCK_NEWS'"
                  class="col-6"
                  @event-refresh-list="handleRefreshList"
              />
          </div>
      </div>
  </section>
</template>
<script>
import UsaStockNewsList from '@/components/newspaper/UsaStockNewsList.vue'
import UsaContent from '@/components/stock/stock-excel-input/UsaContent.vue'
//import Select from '@/components/common/Select.vue'

import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'UsaNews',
  components: {
      'i-stock-list': UsaStockNewsList,
      'i-stock-content': UsaContent,

  },
  data() {
      return {
          radioType:'',
          typeOptions: [
            {
                name: '미국 특징주',
                value: 'USA_STOCK_NEWS',
            },
        ],
      }
  },
  created() {
      this.radioType = this.typeOptions[0].value
      this.setPageStatus(this.PAGE_STATUS.LIST)
  },
  computed: {
      ...mapState({
          PAGE_STATUS: (state) => state.page.status,
          pageStatus: (state) => state.page.pageStatus,
      }),
      ...mapGetters('page', ['isDetailPage', 'isEditPage']),
  },
  methods: {
      ...mapMutations('usa_stock_news', [
          'USA_INIT_LIST',
          'USA_SET_DETAIL',
          'USA_INIT_DETAIL',
      ]),
      ...mapMutations('page', ['setPageStatus']),
      ...mapActions('usa_stock_news', [
          'USA_ASYNC_LIST'] ),
      conV() {
          if (this.isEditPage || this.isDetailPage) {
              if (this.isEditPage) {
                  return true
              } else {
                  return false
              }
          } else {
              return true
          }
      },
      stockConV() {
          if (this.isEditPage || this.isDetailPage) {
              if (this.isDetailPage) {
                  return true
              } else {
                  return false
              }
          } else {
              return true
          }
      },
      handleRefreshList() {
          //  전체 조회하기
          this.USA_INIT_LIST()
          var params = {}
          params.newsType = 'USA_STOCK_NEWS'
          this.USA_ASYNC_LIST(params)
      }
      // ===============
  },
  watch: {
      radioType: function(newVal, oldVal) {
          console.log('watch radioType = ', newVal, ',',oldVal )
          if(newVal !== oldVal) {
              this.type = this.typeOptions.find((v)=> v.value === newVal)
          }
      },
  },
}
</script>
