import Vue from 'vue'
import Router from 'vue-router'
import Debug from './debug'

import { store } from './store/index'
import Login from './views/Login.vue'
import SignUp from './views/SignUp.vue'
import MyInfo from './views/MyInfo.vue'
import Error from './views/Error.vue'
import Index from './views/Index.vue'
import PreviewPopup from './components/common/PreviewPopup'
import AutoFlashPopup from './components/common/AutoFlashPopup'
import SentPopup from './components/common/SentPopup'

import ThemeHistoryPopup from './components/featured/theme/ThemeHistoryPopup'
import FlashListPopup from './components/flash/news-flash/FlashListPopup'

import Main from './views/Main.vue'

//주식신문
import Issue from './views/newspaper/Issue.vue'
import MainDiscl from './views/newspaper/MainDiscl.vue'
import Unusual from './views/newspaper/Unusual.vue'
// 미국 특징주
import UsaNews from './views/newspaper/UsaNews.vue'
import NewRecommend from './views/newspaper/NewRecommend.vue'
import NewsIndex from './views/newspaper/Index.vue'
import USRate from './views/newspaper/USRate.vue'
import TodayWorld from './views/newspaper/TodayWorld.vue'
import IndexPrice from './views/newspaper/IndexPrice.vue'
import PreView from './views/newspaper/PreView.vue'
import PreviewPaper from './components/newspaper/PreviewPaper.vue'
import ViewCount from './views/newspaper/ViewCount.vue'
import CloseIndex from './views/newspaper/CloseIndex.vue' 

// 속보
import NewsFlash from './views/flash/NewsFlash.vue'
import FullPopup from './components/flash/news-flash/FullPopup.vue'
import StockFiltering from './views/flash/StockFiltering.vue'
import StockFlashSetting from './views/flash/StockFlashSetting.vue'
import FlashReserve from './views/flash/FlashReserve.vue'

//특징주/테마
import StockFeatured from './views/featured/StockFeatured.vue'
import ThemeFeatured from './views/featured/ThemeFeatured.vue'
import ThemeTrend from './views/featured/ThemeTrend.vue'
import ThemeDaily from './views/featured/ThemeDaily.vue'

// 시황
import MarketNews from './views/market/MarketNews.vue'
import MarketUSTheme from './views/market/MarketUSTheme.vue'

//import MarketAfterIssue from './views/market/MarketAfterIssue.vue'
import MarketTodaySummary from './views/flash/MarketTodaySummary.vue'

// 종목
import Stock from './views/stock/Stock.vue'
import StockSummaryExtra from './views/stock/StockSummaryExtra.vue'
import StockDiscl from './views/stock/StockDiscl.vue'
import StockExcelInput from './views/stock/StockExcelInput.vue'
import StockStrongBuy from './views/stock/StockStrongBuy.vue'

import StockInvestOpinion from './views/stock/StockInvestOpinion.vue'
import Dividend from './views/stock/Dividend.vue'
import StockHistory from './views/stock/History.vue'
import StockJump from './views/stock/StockJump.vue'

// 테마
import Theme from './views/theme/Theme.vue'
import ThemeStock from './views/theme/ThemeStock.vue'
import ThemeHistory from './views/theme/ThemeHistory.vue'
import ThemeBriefing from './views/theme/ThemeBriefing.vue'


//스케줄
import Schedule from './views/schedule/Schedule.vue'
import ScheduleForm from './views/schedule/ScheduleForm.vue'
import Closed from './views/schedule/Closed.vue'

// 실적
import Earning from './views/earning/Earning.vue'
import EarningSchedule from './views/earning/EarningSchedule.vue'
import Consensus from './views/earning/Consensus.vue'
import Financial from './views/earning/Financial.vue'
import FinancialHistory from './views/earning/FinancialHistory.vue'
import Preview from './views/earning/Preview.vue'

// 마케팅
//      광고
import Advertising from './views/marketing/Advertising.vue'
//     광고 확인
import AdvertisingCnfirm from './views/marketing/AdvertisingCnfirm.vue'


// <!-- 20230920 광고매크로 -->
import AdvertisingMacro from './views/marketing/AdvertisingMacro.vue'

// IR
import IRdaily from './views/irnews/IRdaily.vue'
import IRflash from './views/irnews/IRflash.vue'
import IRManage from './views/irnews/IRManage.vue'
import IRinsight from './views/irnews/IRinsight.vue'
// 관리
import Notice from './views/manage/Notice.vue'
import Staff from './views/manage/Staff.vue'
import StockCompany from './views/manage/StockCompany.vue'
import InBoard from './views/manage/InBoard.vue'
import Member from './views/manage/Member.vue'
import Workmanager from './views/manage/Workmanager.vue'


// 데이터 
import HitsMain from './views/data/HitsMain.vue' // 홈페이지 조회수 

// 전송관리
import Infostock from './views/trnsmis/Infostock.vue'
import CoprtnOxpr from './views/trnsmis/CoprtnOxpr.vue'
import AdvrtsCtmmny from './views/trnsmis/AdvrtsCtmmny.vue'
import LineAdvrts from './views/trnsmis/LineAdvrts.vue'
import BannerAdvrts from './views/trnsmis/BannerAdvrts.vue'
import B2bResend from './views/trnsmis/B2bResend.vue'

//for test
import TestResponse from './views/Test.vue'

import NonePage from './views/NonePage.vue'

import IssuccessAdmin from './views/IssuccessAdmin.vue'


Vue.use(Router)

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/response.htm',
            component: TestResponse
        },
        // 공통
        {
            path: '/',
            meta: { requiresAuth: true }
        },
        {
            path: '/login.htm',
            name: 'Login',
            component: Login
        },
        {
            path: '/advertisingmacro.htm',
            name: 'AdvertisingMacro',
            component: AdvertisingMacro,
        },
        {
            path: '/nonePage.htm',
            name: 'NonePage',
            component: NonePage
        },
        {
            path: '/signup.htm',
            name: 'SignUp',
            component: SignUp
        },
        {
            path: '/main.htm',
            name: 'Main',
            component: Main,
            meta: { requiresAuth: true }
        },
        {
            path: '/myinfo.htm',
            name: 'MyInfo',
            component: MyInfo,
            meta: { requiresAuth: true }
        },
        {
            path: '*',
            component: Error
        },
        {
            path: '/error.htm',
            name: 'Error',
            component: Error
        },
        {
            path: '/preview/popup.htm',
            name: 'PreviewPopup',
            component: PreviewPopup,
            props:true,
            meta: { popup:true }

        },
        {
            path: '/preview/autopopup.htm',
            name: 'AutoFlashPopup',
            component: AutoFlashPopup,
            props:true,
            meta: { popup:true }

        },        
        {
            path: '/view/popup.htm',
            name: 'SentPopup',
            component: SentPopup,
            props:true,
            meta: {  popup:true }

        },
        {
            path: '/issuccessadmin.htm',
            name: 'IssuccessAdmin',
            component: IssuccessAdmin,

        },
        // 221019 meej 테마히스토리 팝업 추가
        {
            path: '/history/ThemePopup.htm',
            name: 'ThemeHistoryPopup',
            component: ThemeHistoryPopup,
            props:true,
            meta: {  popup:true }

        },
        // 221101 meej 테마 속보 리스트 팝업 추가
        {
            path: '/newsflash/FlashListPopup.htm',
            name: 'FlashListPopup',
            component: FlashListPopup,
            props:true,
            meta: {  popup:true }

        },

        /*
         * 주식신문
         */
        {
            path: '/newspaper',
            name: 'NewsPapaer',
            component: Index,
            children:[
                {
                    path: 'issue.htm',
                    name: 'Issue',
                    component: Issue,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'maindiscl.htm',
                    name: 'mainDiscl',
                    component: MainDiscl,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'index.htm',
                    name: 'Index',
                    component: NewsIndex,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'newrecommend.htm',
                    name: 'NewRecommend',
                    component:NewRecommend,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'usaNews.htm',
                    name: 'UsaNews',
                    component:UsaNews,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'unusual.htm',
                    name: 'Unusual',
                    component:Unusual,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'preview.htm',
                    name: 'PreView',
                    component: PreView,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'usrate.htm',
                    name: 'UsRate',
                    component:USRate,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'todayworld.htm',
                    name: 'TodayWorld',
                    component:TodayWorld,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'indexPrice.htm',
                    name: 'IndexPrice',
                    component: IndexPrice,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'text.htm',
                    name: 'Text',
                    meta: { notYet: true, requiresAuth: true }
                },
                {
                    path: 'popup.htm',
                    name: 'PreviewPaper',
                    component: PreviewPaper,
                    props:true,
                    meta: { requiresAuth: true, popup:true }
                },
                {
                    path: 'viewCount.htm',
                    name: 'ViewCount',
                    component:ViewCount,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'closeIndex.htm',
                    name: 'CloseIndex',
                    component:CloseIndex,
                    meta: { requiresAuth: true }
                },
            ]
        },
        /*
         *  속보
         */
        {
            path: '/newsflash',
            name: 'NewsFlash',
            component: NewsFlash,
            children:[
                {
                    path: 'default.htm',
                    name: 'Default',
                    meta: { requiresAuth: true }
                },
                {
                    path: 'news.htm',
                    name: 'News',
                    meta: { requiresAuth: true }
                },               
            ]
        },
        {
            path: '/newsflash/popup.htm',
            name: 'NewsFlashPopup',
            component: FullPopup,
            props: {
                popup: true
            },
            meta: { requiresAuth: true, popup:true }
        },
        {
            path: '/newsflash/todaysummary.htm',
            name: 'MarketTodaySummary',
            component: MarketTodaySummary,
            meta: { requiresAuth: true }
        },        
        {
            path: '/newsflash/flashreserve.htm',
            name: 'FlashReserve',
            component: FlashReserve,
            meta: { requiresAuth: true }
        },
        {
            path: '/newsflash/stockfiltering.htm',
            name: 'StockFiltering',
            component: StockFiltering,
            meta: { requiresAuth: true }
        },
        {
            path: '/newsflash/stockflashsetting.htm',
            name: 'StockFlashSetting',
            component: StockFlashSetting,
            meta: { notYet: false }
        },

        /*
         *  특징종목/테마
         */
        {
            path: '/featured',
            name: 'Featured',
            component: Index,
            children:[
                {
                    path: 'stock.htm',
                    name: 'StockFeatured',
                    component: StockFeatured,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'theme.htm',
                    name: 'ThemeFeatured',
                    component: ThemeFeatured,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'trend.htm',
                    name: 'Trend',
                    component: ThemeTrend,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'daily.htm',
                    name: 'Daily',
                    component: ThemeDaily,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'StockJump.htm',
                    name: 'StockJump',
                    component: StockJump,
                    meta: { requiresAuth: true }
                },
            ]
        },

        /*
         *  시황
         */
        {
            path: '/market',
            name: 'MarketNews',
            component: Index,
            children: [
                {
                    path: 'domestic.htm',
                    name: 'Domestic',
                    component: MarketNews,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'overseas.htm',
                    name: 'MarketUSTheme',
                    component: MarketUSTheme,
                    meta: { requiresAuth: true }
                }
            ]
        },

        /*
         * 종목
         */
        {
            path: '/stock',
            name: 'Stock',
            component: Index,
            children: [
                {
                    path: 'manage.htm',
                    name: 'StockManage',
                    component: Stock,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'summaryextra.htm',
                    name: 'SummaryExtra',
                    component: StockSummaryExtra,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'excelinput.htm',
                    name: 'ExcelInput',
                    component: StockExcelInput,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'strongbuy.htm',
                    name: 'StrongBuy',
                    component: StockStrongBuy,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'investopinion.htm',
                    name: 'InvestOpinion',
                    component: StockInvestOpinion,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'consensus.htm',
                    name: 'Consensus',
                    component: Consensus,
                    meta: { requiresAuth: true }
                },
                //급등락 사유분석
                {
                    path: 'dividend.htm',
                    component: Dividend,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'history.htm',
                    name: 'StockHistory',
                    component: StockHistory,
                    meta: { requiresAuth: true }
                },


            ]
        },

        /*
         * 테마
         */
        {
            path: '/theme',
            name: 'Theme',
            component: Index,
            children:[
                {
                    path: 'manage.htm',
                    name: 'ThemeManage',
                    component: Theme,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'themestock.htm',
                    name: 'ThemeStock',
                    component: ThemeStock,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'history.htm',
                    name: 'History',
                    component: ThemeHistory,
                    meta: { requiresAuth: true }
                },


                {
                    path: 'themebriefing.htm',
                    name: 'ThemeBriefing',
                    component: ThemeBriefing,
                    meta: { requiresAuth: true }
                },
            ]
        },

        /*
         * 스케줄
         */
        {
            path: '/event',
            name: 'Event',
            component: Index,
            children: [
            {
                path: 'manage.htm',
                component: Schedule,
                name: 'EventManage',
                meta: { requiresAuth: true }
            },
            {
                path: 'scheduleForm.htm',
                component: ScheduleForm,
                name: 'scheduleForm',
                meta: { requiresAuth: true }
            },
            {
                path: 'closed.htm',
                component: Closed,
                name: 'Cloesd',
                meta: { requiresAuth: true }
            }]
        },

        /*
         * 실적
         */
        {
            path: '/earning',
            name: 'Earning',
            component: Index,
            children:[
                {
                    path: 'earning.htm',
                    name: 'Earning',
                    component: Earning,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'schedule.htm',
                    name: 'EarningSchedule',
                    component: EarningSchedule,
                    meta: { requiresAuth: true }
                },
                
                {
                    path: 'preview.htm',
                    name: 'EarningPreview',
                    component: Preview,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'financial.htm',
                    name: 'Financial',
                    component: Financial,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'financialHistory.htm',
                    name: 'FinancialHistory',
                    component: FinancialHistory,
                    meta: { requiresAuth: true }
                },

            ]
        },

        /*
         * 마케팅
         */
        {
            path: '/marketing',
            name: 'Marketing',
            component: Index,
            children:[
                {
                    path: 'advertising.htm',
                    name: 'Advertising',
                    component: Advertising,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'advertisingCnfirm.htm',
                    name: 'AdvertisingCnfirm',
                    component: AdvertisingCnfirm,
                    meta: { requiresAuth: true }
                },
                // <!-- 20230920 광고매크로 -->
                // {
                //     path: 'advertisingmacro.htm',
                //     name: 'AdvertisingMacro',
                //     component: AdvertisingMacro,
                //     // meta: { requiresAuth: true }
                // },
            ]
        },
        /*
         * IR
         */
        {
            path: '/irnews',
            name: 'IRNews',
            component: Index,
            children:[
                {
                    path: 'daily.htm',
                    name: 'IRdaily',
                    component: IRdaily,
                    meta: { notYet: true, requiresAuth: true }
                },
                {
                    path: 'flash.htm',
                    name: 'IRflash',
                    component: IRflash,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'manage.htm',
                    name: 'IRManage',
                    component: IRManage,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'irinsight.htm',
                    name: 'IRinsight',
                    component: IRinsight,
                    meta: { notYet: false, requiresAuth: true }
                }
            ]
        },
        {
            path: '/data',
            name: 'Data',
            component: Index,
            children:[
                {
                    path: 'discl.htm',
                    name: 'Discl',
                    component: StockDiscl,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'hitsMain.htm',
                    name: 'hitsMain',
                    component: HitsMain,
                    meta: { requiresAuth: true }
                },
            ]
        },

        /*
         * 관리
         */
        {
            path: '/manage',
            name: 'Manage',
            component: Index,
            children:[
                {
                    path: 'notice.htm',
                    name: 'Notice',
                    component: Notice,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'staff.htm',
                    name: 'Staff',
                    component: Staff,
                    meta: { requiresAuth: true }
                    
                },
                {
                    path: 'workmanager.htm',
                    name: 'Workmanager',
                    component: Workmanager,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'stockcompany.htm',
                    name: 'StockCompany',
                    component: StockCompany,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'member.htm',
                    name: 'Member',
                    component: Member,
                    meta: { notYet: false, requiresAuth: true }
                },
                {
                    path: 'inboard.htm',
                    name: 'InBoard',
                    component: InBoard,
                    meta: { requiresAuth: true }
                },
            ]
         },
        /*
         * 전송관리 시스템
         */
        {
            path: '/trnsmis',
            name: 'Trnsmis',
            component: Index,
            children:[
                {
                    path: 'infostock.htm',
                    name: 'Infostock',
                    component: Infostock,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'resend.htm',
                    name: 'B2bResend',
                    component: B2bResend,
                    meta: { requiresAuth: true }
                },
                
                {
                    path: 'coprtnOxpr.htm',
                    name: 'CoprtnOxpr',
                    component: CoprtnOxpr,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'advrtsCtmmny.htm',
                    name: 'AdvrtsCtmmny',
                    component: AdvrtsCtmmny,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'lineAdvrts.htm',
                    name: 'LineAdvrts',
                    component: LineAdvrts,
                    meta: { requiresAuth: true }
                },
                {
                    path: 'bannerAdvrts.htm',
                    name: 'BannerAdvrts',
                    component: BannerAdvrts,
                    meta: { requiresAuth: true }
                }
            ]
         }

    ]
})
Router.prototype.open = function (routeObject) {
  const {href} = this.resolve(routeObject)
  window.open(href, '시황속보 미리보기','width=620,height=820')
}
router.beforeEach((to, from, next) => {
    let name = to.name

    Debug.log('router beforeEach to Path = ', to.path)
    Debug.log('router  beforeEach to Name = ', name)
    //if(to.path.equals('/response.htm')) {
    Debug.log('response = ', to)
    //}
    if (to.matched.some(record => record.meta.popup)) {
        store.commit('page/setPopupPage')
    } else {
        // page status를 List로 설정
        store.commit('page/setListPage')
    }


    if (to.matched.length != 0) {
        Debug.log('beforeEach to parentRoute = ', to.matched[0].name)
        store.commit('resource/SET_MENU', to.matched[0].name)
    }

    if (to.matched.some(record => record.meta.notYet)) { // 작업중 페이지는 error 페이지로 이동
        router.push({ name: 'Error', params: { message: name } })
    } else if (to.matched.some(record => record.meta.requiresAuth)) { // 세션 체크 필요한 페이지는 세션체크 후 페이지로 이동
        Debug.log('Router requiresAuth !!!!!!!!!!!!!!!!!!')
        store.dispatch('session/check_session').then(() => {
            if (to.path !== '/') {
                Debug.log('beforeEach to path = ', to.path)
                next()
                store.commit('session/changePos', name)
            } else {
                next({ name: 'Main' })
                store.commit('session/changePos', 'Main')
            }
        })
        .catch((error) => {
            Debug.log("----------------beforeEach error", error)
            if (error.invalid) {
                // 로그인 화면
                store.dispatch('session/invalid_session')
                if (to.path !== '/') {
                    alert(error.data)
                }
            } else {
                let message = error.data.response.data
                if (error.data.response.data.message) {
                    message = error.data.response.data.message
                }
                next({ name: 'Error', params: { message: message } })
            }
        })
    } else { // 바로 페이지 이동
        if (store.getters['session/isLoggedIn'] && to.path === '/login.htm') {
            var isLogout = confirm("로그아웃 하시겠습니까?")
            if (isLogout) {
                store.dispatch('session/logout_action', true)
            } else {
                next({ name: 'Main' })
                store.commit('session/changePos', 'Main')
            }
        } else {
            next()
            store.commit('session/changePos', name)
        }
    }
})
export default router