<template>
  <div class="stock-search">
    <div class="search-container">
      <!-- 드롭다운 메뉴 -->
      <select v-model="searchType" class="search-dropdown">
        <option value="name">종목명</option>
        <option value="code">종목코드</option>
      </select>

      <!-- 검색 입력 필드 -->
      <input
        type="text"
        v-model="keyword"
        class="search-input"
        :placeholder="searchType === 'name' ? '종목명을 입력하세요' : '종목코드를 입력하세요'"
        @keyup.enter="handleSearch"
      />

      <!-- 검색 버튼 -->
      <!-- <button @click="handleSearch" class="search-button">검색</button> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "UsaStockSearch",
  data() {
    return {
      searchType: "name", // 검색 타입 (종목명 또는 종목코드)
      keyword: "", // 검색어
    };
  },
  methods: {
    handleSearch() {
      if (!this.keyword.trim()) {
        alert("검색어를 입력하세요.");
        return;
      }

      // 부모 컴포넌트로 검색 타입과 키워드 전달
      this.$emit("search", {
        type: this.searchType,
        keyword: this.keyword.trim(),
      });

      // 검색어 초기화
      this.keyword = "";
    },
  },
};
</script>

<style scoped>
.stock-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-container {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;
}

.search-dropdown {
  height: 40px;
  padding: 4px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.search-input {
  flex: 1;
  height: 40px;
  max-width: 145px;
  padding: 4px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 13px;
}

.search-button {
  height: 36px;
  max-width: 40px;
  padding: 0 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  cursor: pointer;
  flex-shrink: 0;
}

.search-button:hover {
  background-color: #0056b3;
}
</style>
