<template>
    <article>
        <q-scroll-area visible class="i-content-wrap">
            <q-card flat bordered>
                <q-card-section>
                    <div class="row">
                        <div class="col-6 q-pa-xs">
                            <i-input
                                v-model="stockItem.code"
                                inputLabel="종목코드"
                                :disable="isEditPage"
                            />
                        </div>
                        <div class="col-6 q-pa-xs">
                            <i-input
                                v-model="stockItem.name"
                                inputLabel="종목명"
                                :disable="isEditPage"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 q-pa-xs">
                            <i-select
                                v-model="stockItem.corpCls"
                                inputLabel="회사구분"
                                :options="corpClsOptions"
                                @input="selectCorpCls()"
                                :disable="isEditPage"
                            />
                        </div>
                        <div class="col-6 q-pa-xs">
                            <i-select
                                v-model="stockItem.part"
                                inputLabel="업종구분"
                                :options="stockPartOptions"
                                option-label="name"
                                option-value="code"
                            />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6 q-pa-xs">
                            <i-select
                                v-model="stockItem.type"
                                inputLabel="상장구분"
                                :options="stockType"
                                option-label="name0"
                                option-value="value0"
                                :bgColor="bgColor"
                            />
                        </div>
                        <div
                            v-if="
                                stockItem.type &&
                                    stockItem.type.value0 != 'DELISTING'
                            "
                            class="col-12 col-md-6 q-pa-xs"
                        >
                            <i-datetime
                                date
                                v-model="stockItem.listingDate"
                                dateLabel="상장일"
                                :disable="isEditPage"
                            />
                        </div>
                    </div>
                    <div v-show="!isRegisterPage" class="row">
                        <div class="col-6 q-pa-xs">
                            <i-field
                                check
                                v-model="stockItem.ir"
                                inputLabel="IR계약업체"
                                :disable="isEditPage"
                            />
                        </div>
                    </div>
                    <div v-show="!isRegisterPage && stockItem.ir" class="row">
                        <!-- 220719 meej IR 개요 추가 
                             220721 meej 레이아웃 수정, row 나눔 -->
                        <div class="col-12"
                            v-if="stockItem.ir">
                            <i-input
                                v-model="stockItem.c_item.irSummary"
                                inputLabel="IR 개요"
                                type="textarea"
                                :rows="10"
                            />
                        </div>
                    </div>
                    <div v-show="isRegisterPage" class="row">
                        <div class="col-12 q-pa-xs">
                            <i-input
                                v-model="stockItem.content"
                                inputLabel="기업개요"
                                type="textarea"
                                :rows="10"
                            />
                        </div>
                    </div>
                    <div v-if="!isRegisterPage" class="row q-my-sm">
                        <div v-if="stockItem.c_item" class="col-12">
                            <i-input
                                v-model="stockItem.c_item.content"
                                inputLabel="기업개요"
                                type="textarea"
                                :rows="10"
                                :disable="isEditPage"
                            />
                            <div class="text-right">
                                <!-- TODO: createTime이 맞는지 확인 필요 -->
                                <!-- 
                                    220520 meej 작성자도 같이 나오게 수정 
                                    220524 meej 작성자 null일 경우 안나오게 수정
                                -->
                                <span v-if="stockItem.c_item">
                                    <span v-if="stockItem.c_item.writer != null">
                                        (최종수정일:{{FormatDateTime( stockItem.c_item.createTime)+" "+stockItem.c_item.writer}})
                                    </span>
                                    <span v-else>
                                        (최종수정일:{{FormatDateTime(stockItem.c_item.createTime)}})
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="col-12 q-pa-xs" v-else>
                            <i-input
                                v-model="stockItem.content"
                                inputLabel="기업개요"
                                type="textarea"
                                :rows="10"
                            />
                        </div>
                    </div>
                    <div
                        v-if="isListPage && stockItem.t_items.length > 1"
                        class="flex justify-end"
                    >
                        <q-btn
                            v-show="minIndex != 0"
                            flat
                            color="dark"
                            icon="arrow_upward"
                            @click="moveUp()"
                            :disable="clickItem.length == 0"
                        />
                        <q-btn
                            v-show="maxIndex != stockItem.t_items.length - 1"
                            flat
                            color="dark"
                            icon="arrow_downward"
                            @click="moveDown()"
                            :disable="clickItem.length == 0"
                        />
                        <q-btn
                            flat
                            color="dark"
                            label="순서변경"
                            @click="order()"
                            :disable="clickItem.length == 0"
                        />
                    </div>
                    <div v-show="!isRegisterPage" class="row q-my-sm">
                        <div class="col-12">
                            <div
                                v-for="(item, index) in stockItem.t_items"
                                :key="'t_item-' + index"
                                class="q-my-sm"
                                @click.prevent="click(index)"
                            >
                                <!-- TODO: 삭제된 테마 구분 필요.  -->
                                <q-field
                                    outlined
                                    :bg-color="isClicked(index)"
                                    label-slot
                                    class="q-field--float"
                                >
                                    <template v-slot:before>
                                        <label class="i-label">
                                            <span v-show="index == 0"
                                                >테마기업요약</span
                                            >
                                            <q-btn
                                                v-show="
                                                    index == 0 && !isEditPage
                                                "
                                                label="수정"
                                                @click="goEditPage()"
                                                color="secondary"
                                                unelevated
                                            />
                                        </label>
                                    </template>
                                    <template v-slot:label>
                                        <span
                                            class=" q-px-sm text-white rounded-borders"
                                            :class="
                                                item.type == 'DELETE'
                                                    ? 'bg-negative'
                                                    : item.type == 'PUBLIC'
                                                    ? 'bg-primary'
                                                    : 'bg-warning'
                                            "
                                            >{{ getKrType(item.type).name0 }}
                                        </span>
                                    </template>
                                    <template v-slot:control>
                                        <div
                                            class="self-center full-width no-outline"
                                            tabindex="0"
                                        >
                                            <p>
                                                {{ [item.themeName] }}
                                                <span
                                                    v-if="item.lastUpdateTime"
                                                >
                                                    {{
                                                        FormatDateTime(
                                                            item.lastUpdateTime
                                                        )
                                                    }}
                                                </span>
                                                {{ item.themeCode }}<br />
                                                {{ item.outline }}
                                            </p>
                                        </div>
                                    </template>
                                </q-field>
                            </div>
                        </div>
                    </div>
                    <div v-show="!isRegisterPage">
                        <div
                            v-for="(item, index) in stockItem.c_items"
                            :key="'c_item-' + index"
                            class="q-my-sm"
                        >
                            <q-field v-show="index > 0" filled disable>
                                <template v-slot:before>
                                    <label class="i-label">
                                        <span v-show="index == 1"
                                            >기업개요 수정내역</span
                                        >
                                    </label>
                                </template>
                                <template v-slot:control>
                                    <div
                                        class="self-center full-width no-outline"
                                        tabindex="0"
                                    >
                                        {{ item.content }}
                                        <br /><br />
                                        <!-- 
                                            220520 meej 작성자도 같이 나오게 수정 
                                            220524 meej 작성자 null일 경우 안나오게 수정
                                        -->
                                        <span  v-if="item.writer != null">
                                            (작성일시:{{ FormatDateTime(item.createTime)+" "+item.writer }})
                                        </span>
                                        <span v-else>
                                            (작성일시:{{ FormatDateTime(item.createTime)}})
                                        </span>
                                    </div>
                                </template>
                            </q-field>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </q-scroll-area>
        <div class="q-my-md">
            <i-btn
                v-show="!isRegisterPage"
                class="full-width"
                label="수정"
                @click="write()"
                :disable="isEditPage"
            />
            <i-btn
                v-show="isRegisterPage"
                class="full-width"
                label="등록"
                @click="write()"
            />
        </div>
    </article>
</template>
<script>
import Debug from '@/debug'
import CONSTANTS from '@/constants'
import { FormatDateTime } from '@/util'

import Button from '@/components/common/Button'
// import Input from '@/components/common/Input'
import Input from '@/components/common/ExceptionInput'
import Select from '@/components/common/Select'
import Field from '@/components/common/Field'
import DateTime from '@/components/common/DateTime'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Content',
    props: {
        condition: {
            type: Object,
            default: null,
        },
    },
    components: {
        'i-btn': Button,
        'i-input': Input,
        'i-select': Select,
        'i-field': Field,
        'i-datetime': DateTime,
    },
    data() {
        return {
            bgColor: null,
            clickItem: [],
            corpClsOptions: CONSTANTS.CORPCLS_TYPE,
            stockPartOptions: [],
        }
    },
    created() {
        this.GET_STOCK_PART()
    },
    computed: {
        ...mapState({
            PAGE_STATUS: (state) => state.page.status,
            pageStatus: (state) => state.page.pageStatus,
            stockType: (state) => state.resource.stockType,
            themeStockType: (state) => state.resource.themeStockType,
            stockItem: (state) => state.stock.item,
            stockPart: (state) => state.resource.stockPart,
            list: (state) => state.stock.list,
            stockItems: (state) => state.resource.stockItems, 
            tempCode: (state) => state.stock.tempCode,  
        }),
        ...mapGetters('page', ['isListPage', 'isEditPage', 'isRegisterPage']),

        maxIndex() {
            return Math.max.apply(null, this.clickItem)
        },
        minIndex() {
            return Math.min.apply(null, this.clickItem)
        },
        cItemsLastIndex() {
            return this.stockItem.c_items.length - 1
        },
    },
    methods: {
        ...mapMutations('page', ['setPageStatus']),
        ...mapMutations('stock', [
            'SET_LIST',
            'SET_DETAIL',
            'SET_CODE',
            'SET_THEME_STOCK',
            'INIT_LIST',
            'INIT_DETAIL',
        ]),
        ...mapActions('resource', ['GET_INIT', 'GET_STOCK_PART']),
        ...mapActions('alert', ['showError', 'showMessage', 'showAlert']),
        ...mapActions('stock', [
            'GET_LIST',
            'GET_DETAIL',
            'GET_HISTORY',
            'GET_THEME_STOCK',
            'WRITE_ITEM',
            'DELETE_ITEM',
            'ORDER_ITEMS',
        ]),

        // 20210820 shb | 테마 기업요약 표시시 공개, 준비, 편출 다 다르게 표시됐으면 좋겠습니다 -- 요청 추가
        getKrType(type) {
            return this.themeStockType.find((el) => el.value0 == type)
        },

        isClicked: function(index) {
            return this.clickItem.includes(index) ? 'yellow' : 'lightgrey'
        },

        goListPage() {
            this.setPageStatus(this.PAGE_STATUS.LIST)
        },
        goEditPage() {
            this.setPageStatus(this.PAGE_STATUS.EDIT)
        },
        goHistoryPage() {
            // this.SET_CODE(this.stockItem.code)
            var params = {
                code: this.stockItem.code,
            }
            this.GET_HISTORY(params)

            this.setPageStatus(this.PAGE_STATUS.HISTORY)
        },
        goRegisterPage() {
            this.setPageStatus(this.PAGE_STATUS.REGISTER)

            this.INIT_DETAIL()
        },
        selectCorpCls() {
            // 회사구분에 따라 업종구분 옵션 변경
            var corpCls = this.stockItem.corpCls.value
            Debug.log('selectCorpCls = ',this.stockItem.corpCls)

            this.stockPartOptions = this.stockPart.filter(
                (v) => v.pcode == corpCls
            )
            this.stockItem.part = this.stockPartOptions[0]
            //Debug.log('stockPart = ',this.stockPart)
            Debug.log('stockPartOptions = ',this.stockPartOptions)
        },
        // 상세 조회하기
        getDetail(code) {
            var params = {}
            params.code = code

            this.GET_DETAIL(params)
                .then((data) => {
                    data.type = this.stockType.find(
                        (v) => v.value0 == data.type
                    )
                    this.SET_DETAIL(data)
                })
                .catch((error) => {
                    Debug.log('error = ', error)
                    this.showError({ vc: this, error: error })
                })
        },
        // 목록 조회하기
        getList() {
            var params = {
                type: this.condition.type.value0,
                corpCls: this.condition.corpCls.value,
            }

            this.GET_LIST(params)
                .then((data) => {
                    var items = data.items
                    this.SET_LIST(items)
                    console.log('items: ', items)

                    this.stockCodeTemp = items

                    /* 
                        220329 meej
                        수정 시 detail내용 해당 종목코드로 불러오기
                             -> write()에서 처리
                    */
                    if(!this.isListPage){
                        if (items[0]) {
                            this.getDetail(items[0].code)
                        }
                    }
                })
                .catch((err) => {
                    Debug.log('error = ', err)
                    var error = err.message
                    this.showError({ vc: this, error: error })
                })
        },
        // 등록 및 수정
        write() {
            // 22.07.04 suye 등록시 기존 종목코드가 존재하는지 체크 
            let stockCheck = this.stockItems.find(
                (element) => element.code == this.stockItem.code 
            )
            if(stockCheck !== undefined) {
                if(this.isRegisterPage){ // 등록
                    this.showMessage({ vc: this, message: '이미 존재하는 종목코드입니다.'})
                    return
                } 
                // else if(this.isListPage){ // 수정
                //     if(this.stockItem.code !== this.tempCode){ // 새 종목코드 !== 기존 종목코드
                //         this.showMessage({ vc: this, message: '변경된 종목코드 ['+this.stockItem.code+']은/는 이미 존재합니다.'})
                //         return
                //     }
                //     // console.log('stockItem.code', this.stockItem.code, 'tempCode', this.tempCode)
                // }
            }
            
            if (
                !this.stockItem.code ||
                !this.stockItem.name ||
                !this.stockItem.corpCls ||
                !this.stockItem.part ||
                !this.stockItem.type
            ) {
                this.showMessage({ vc: this, message: '내용을 입력해주세요.' })
                return
            }

            if (this.isRegisterPage && !this.stockItem.content) {
                this.showMessage({ vc: this, message: '내용을 입력해주세요.' })
                return
            }

            if (this.stockItem.code.length != 6) {
                this.showMessage({
                    vc: this,
                    message: '종목코드 자리수를 확인해주세요.',
                })
                return
            }
            
            if (
                this.isListPage &&
                !(this.stockItem.c_item || this.stockItem.content)
            ) {
                this.showMessage({ vc: this, message: '내용을 입력해주세요.' })
                return
            }

            // 파라미터 생성
            var params = {
                code: this.stockItem.code,
                name: this.stockItem.name,
                corpCls: this.stockItem.corpCls.value,
                partCode: this.stockItem.part.code,
                partName: this.stockItem.part.name,
                type: this.stockItem.type.value0,
                listingDate: this.stockItem.listingDate,
            }

            if (this.isListPage) {
                params.content = this.stockItem.c_item
                    ? this.stockItem.c_item.content
                    : this.stockItem.content
                params.ir = this.stockItem.ir ? 1 : 0
                // 220719 meej IR계약업체 체크시 IR 개요 따로 저장
                if(this.stockItem.ir){
                    params.irSummary = this.stockItem.c_item.irSummary
                }
            }

            if (this.isRegisterPage) {
                params.content = this.stockItem.content
            }

            this.WRITE_ITEM(params)
                .then(() => {
                    var message
                    if (this.isListPage) {
                        message = '수정되었습니다.'
                    }
                    if (this.isRegisterPage) {
                        message = '등록되었습니다.'
                    }
                    this.showMessage({ vc: this, message: message })
   
                    // stock list 업데이트

                    // 22.04.01 suye 수정 후 리로딩 해제 
                    // this.GET_INIT()
                    // this.INIT_LIST()
                    // this.getList()
                    // this.goListPage()

                    /*  
                        220329 meej
                        수정 시 detail내용 해당 종목코드로 불러오기
                    */
                    this.getDetail(params.code)

                    this.getList() // 등록&수정 후 리스트 불러오기 
                })
                .catch((error) => {
                    Debug.log('error = ', error)
                    this.showError({ vc: this, error: error })
                })
        },

        // 순서변경하기
        order() {
            var arr = []
            Debug.log(this.stockItem.t_items)
            for (var i in this.stockItem.t_items) {
                var item = this.stockItem.t_items[i]
                var obj = {}
                obj.themeCode = item.themeCode
                obj.index = i

                arr.push(obj)
            }
            Debug.log(arr)

            var params = {}
            params.stockCode = this.stockItem.code
            params.items = arr

            var message

            this.ORDER_ITEMS(params)
                .then(() => {
                    message = '순서변경되었습니다.'
                    this.showMessage({ vc: this, message: message })

                    this.clickItem = []
                })
                .catch((err) => {
                    Debug.log('error = ', err)
                    var error = err.message
                    this.showError({ vc: this, error: error })
                })
        },
        /*
            순서 변경 메소드
        */
        click(index) {
            // TODO: 테마기업요약 삭제된 테마는 선택불가하도록 하기
            if (this.isEditPage) {
                // 수정모드일때

                var tItem = this.stockItem.t_items[index]

                var params = {}
                params.themeCode = tItem.themeCode
                params.stockCode = tItem.stockCode

                this.GET_THEME_STOCK(params)
                    .then((data) => {
                        data.type = this.themeStockType.find(
                            (v) => v.value0 == data.type
                        )
                        this.SET_THEME_STOCK(data)
                    })
                    .catch((error) => {
                        console.log('??????')
                        this.showError({ vc: this, error: error })
                    })

                // this.SET_THEME_STOCK(tItem)
                this.clickItem = []
                this.clickItem.push(index)
            } else {
                // 상세모드일때
                if (this.stockItem.t_items.length > 1) {
                    // 클릭된 아이템이 없는 경우
                    if (this.clickItem.length == 0) {
                        this.clickItem.push(index)
                    } else {
                        if (this.clickItem.includes(index)) {
                            // 인덱스가 이미 있는 경우, 해당 인덱스를 제거한다.
                            if (
                                index == this.maxIndex ||
                                index == this.minIndex
                            ) {
                                this.clickItem.splice(
                                    this.clickItem.indexOf(index),
                                    1
                                )
                            }
                        } else {
                            // 인덱스가 없는 경우, 해당 인덱스를 추가한다.
                            if (
                                index == this.maxIndex + 1 ||
                                index == this.minIndex - 1
                            ) {
                                this.clickItem.push(index)
                            }
                        }
                    }
                }
            }
        },
        moveUp() {
            var tempArr = this.stockItem.t_items
            var temp = tempArr[this.minIndex - 1]

            for (var i = this.minIndex; i < this.maxIndex + 1; i++) {
                tempArr[i - 1] = tempArr[i]
            }
            tempArr[this.maxIndex] = temp
            this.stockItem.t_items = tempArr

            this.clickItem = this.clickItem.map((v) => {
                return v - 1
            })
        },
        moveDown() {
            var tempArr = this.stockItem.t_items
            var temp = tempArr[this.maxIndex + 1]

            for (var i = this.maxIndex; i > this.minIndex - 1; i--) {
                tempArr[i + 1] = tempArr[i]
            }
            tempArr[this.minIndex] = temp
            this.stockItem.t_items = tempArr

            this.clickItem = this.clickItem.map((v) => {
                return v + 1
            })
        },
        FormatDateTime(datetime) {
            return FormatDateTime(datetime)
        },
    },
    watch: {
        stockItem: function() {
            // 테마기업요약 순서변경을 위해 체크된 것 초기화
            this.clickItem = []

            // 회사구분에 따라 업종구분 옵션 변경
            if (this.stockItem.corpCls) {
                var corpCls = this.stockItem.corpCls.value //.repeat(6)
                this.stockPartOptions = this.stockPart.filter(
                    (v) => v.pcode == corpCls
                )
            }
        },
        // 220524 meej 종목관리 - 상장구분 : 상장폐지 일 경우 배경색 : red-11로 설정
        // 221018 meej 상장예정 일 경우 배경색 : blue-11로 설정
        'stockItem.type': function(){
            if(this.stockItem.type.value0 == "DELISTING"){
                this.bgColor = 'red-11'
            }else if(this.stockItem.type.value0 == "TOBE_LISTED"){
                this.bgColor = 'blue-11'
            }else{
                this.bgColor = null
            }
        }
    },
}
</script>
