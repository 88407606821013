import api from "@/services/InfostockService";
import Debug from "@/debug";

const state = () => ({
  list: [],
  nextKey: null,
  item: null,
  flashType: null,
  options: null,
  stockCompany: [],
  FeturedStockFlashType: [],
  keyword: "",
});

const getters = {};

const mutations = {
  SET_LIST(state, { data }) {
    var arr = state.list;
    arr.push(...data.items);

    // 인덱스 추가
    arr.forEach((row, index) => {
      row.index = index;
      row.checked = false;
    });

    state.list = arr;
    state.nextKey = data.nextKey;
  },
  setStockCompany(state, list) {
    state.stockCompany = list;
  },
  USA_SET_DETAIL(state, item) {
    Debug.log("SET_DETAIL item", item);
    state.item = item;
  },
  USA_INIT_LIST(state) {
    state.list = [];
    state.nextKey = null;
  },
  SET_FLASH_TYPE(state, flashType) {
    state.flashType = flashType;
  },
  USA_INIT_DETAIL(state) {
    state.item = null;
  },
  SET_OPTIONS(state, options) {
    state.options = options;
  },
  setNewsTypeList(state, { data }) {
    state[data.newsType] = data.newsTypes;
  },
  SET_SORTED_LIST(state, items) {
    var arr = state.list;
    arr.push(...items);
    // 인덱스 추가
    arr.forEach((row, index) => {
      row.index = index;
      row.checked = false;
    });
    state.list = arr;
  },
  SET_NEXTKEY(state, nextKey) {
    state.nextKey = nextKey;
  },
  SET_KEYWORD(state, keyword) {
    state.keyword = keyword;
  },
};

const actions = {
  // 목록 조회
  async USA_ASYNC_LIST({ state, commit }, params) {
    params.nextKey = state.nextKey;
    const { data } = await api.UsaStockNewsList(params);
    commit("SET_LIST", { data });
  },
  // 230215 meej SPOT 입력 리스트 조회 API 추가
  async ASYNC_SPOT_LIST({ state, commit }, params) {
    params.nextKey = state.nextKey;
    const { data } = await api.stockNewsSpotList(params);

    // 230711 LJW 입력날짜 순으로 정렬
    const items = data.items;

    // createTime을 기준으로 items 배열 내림차순 정렬
    items.sort((a, b) => {
      const showDateA = a.showDate;
      const showDateB = b.showDate;
      return showDateB.localeCompare(showDateA); // 내림차순으로 정렬
    });

    // data 객체에 정렬된 items 배열 할당
    data.items = items;

    commit("SET_LIST", { data });
  },
  async USA_ASYNC_DATE_LIST({ state, commit }, params) {
    params.nextKey = state.nextKey;
    const { data } = await api.USAstockNewsListByDate(params);
    commit("SET_LIST", { data });
  },

  async USA_ASYNC_SEARCH_LIST({ state, commit }, params) {
    params.nextKey = state.nextKey;
    const { data } = await api.USAstockNewsSearch(params);
    commit("SET_LIST", { data });
  },

  GET_LIST(_, params) {
    /* 220623 meej 페이징 오류 수정 주석처리 */
    // params.nextKey = state.nextKey
    return new Promise((resolve, reject) => {
      api
        .stockNewsList(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  GET_DATE_LIST(_, params) {
    return new Promise((resolve, reject) => {
      api
        .stockNewsListByDate(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 상세 조회
  async ASYNC_DETAIL({ commit }, params) {
    const { data } = await api.stockNewsDetail(params);

    commit("USA_SET_DETAIL", data.item);
  },
  // 상세 조회
  GET_DETAIL(_, params) {
    Debug.log("GET_DETAIL params", params);
    return new Promise((resolve, reject) => {
      api
        .stockNewsDetail(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 작성 및 수정
  USA_WRITE_ITEM(_, params) {
    return new Promise((resolve, reject) => {
      api
        .UsastockNewsWrite(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 작성 및 수정
  WRITE_FEATURED_ITEM(_, params) {
    return new Promise((resolve, reject) => {
      api
        .stockNewsFeaturedWrite(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 220701 meej WRITE_FEATURED_ITEM_LIST 추가 , 특징주 여러개 등록 가능
  WRITE_FEATURED_ITEM_LIST(_, list) {
    return new Promise((resolve, reject) => {
      api
        .stockNewsFeaturedWriteList(list)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 엑셀입력
  USA_EXCEL_WRITE_ITEM(_, params) {
    return new Promise((resolve, reject) => {
      api
        .UsastockNewsExcelWrite(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 여러개 삭제
  USA_DELETE_ITEMS(_, params) {
    return new Promise((resolve, reject) => {
      api
        .USAstockNewsMultiDelete(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async ASYNC_STOCK_COMPANY({ state, commit }) {
    var params = {};
    params.newsType = state.newsType;

    Debug.log("GET_STOCK_COMPANY params", params);
    const { data } = await api.setStockCompany(params);
    Debug.log("GET_STOCK_COMPANY data", data);

    commit("SET_STOCK_COMPANY", data.items);
  },
  CHANGE_NEWSTYPE_ITEMS(_, params) {
    return new Promise((resolve, reject) => {
      api
        .stockNewsChangeNewsType(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // 220905 meej 특징주 - 이슈이동
  CHANGE_ISSUE(_, params) {
    return new Promise((resolve, reject) => {
      api
        .stockNewsChangeIssue(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  REGIST_ISSUE(_, params) {
    return new Promise((resolve, reject) => {
      api
        .stockNewsIssue(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  ORDER_ITEMS(_, params) {
    return new Promise((resolve, reject) => {
      api
        .stockNewsFeaturedOrder(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  GET_FEATURED_DETAIL(_, params) {
    return new Promise((resolve, reject) => {
      api
        .stockNewsFeaturedDetail(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  CONFIRM_FEATURED(_, params) {
    return new Promise((resolve, reject) => {
      api
        .stockNewsFeaturedConfirm(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  CONFIRM_NEWS(_, params) {
    return new Promise((resolve, reject) => {
      api
        .stockNewsConfirm(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  //뉴스타입 조회
  GET_FLASH_NEWSTYPE(_, newsType) {
    const params = { newsType: newsType };
    return new Promise((resolve, reject) => {
      api
        .newsType(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
    // const params = {newsType : newsType}
    // const { data } = await api.newsType(params)
    // commit('setNewsTypeList', { data })
  },
  /* 220511 meej UPDATE_RATE 추가, 특징주 : 등락률 업데이트 기능 */
  UPDATE_RATE(_, params) {
    return new Promise((resolve, reject) => {
      api
        .stockNewsChageRate(params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export const usa_stock_news = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
