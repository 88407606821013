<!-- 20230920 광고매크로 -->
<template>
  <section class="row q-pl-xs">
      <i-list ref="advertisingList" :trnsmis="trnsmis" :sendDate="sendDate" class="col-12 q-pa-xs"/>
      <!-- <i-content ref="advertisingContent" :trnsmis="trnsmis"/> -->
  </section>
</template>
<script>
// import CONSTANTS from '@/constants'
import List from "@/components/marketing/advertising/MacroList.vue";
// import Content from "@/components/marketing/advertising/Content.vue";
import { mapState, mapActions } from 'vuex'
import debug from '@/debug'
export default {
  name: "AdvertisingMacro",
  components: {
      "i-list": List,
    //   'i-content': Content
  },
  data() {
      return {
          trnsmis: null,
          trnsmisIdOptions:[],
          sendDate: null
      }
  },
  computed: {
      ...mapState ({
        //   role : state => state.session.role,
        //   userId : state => state.session.userId,
          TRANS_TYPE : state => state.manage.transType,
      }),
  },
  created () {
      this.getPage();
  },
  methods: {
      ...mapActions('alert', ['showError', 'showMessage', 'showAlert', 'showDeleteAlert']),
      ...mapActions('manage', ['GET_CALL']),
    //   페이지 검색
      getPage() {
          let param = {
              trnsmisType:this.TRANS_TYPE.ADVRTS_CTMMNY,
              CALL_API_URL: '/adnews/page2'
          }
          this.GET_CALL(param).then((data) => {
              this.trnsmisIdOptions = data.selectList
            //   if(this.role != CONSTANTS.ROLE.CU) {
            //       this.trnsmisIdOptions.unshift({trnsmisId:'', trnsmisNm:'전체'});
            //   }
              this.trnsmisIdOptions.unshift({trnsmisId:'', trnsmisNm:'전체'});
              debug.log('getPage', this.trnsmisIdOptions)
              this.trnsmis = this.trnsmisIdOptions[0];
              
              this.getList();
          }).catch((error) => {
              this.showError({ vc:this, error:error })
          });
      },
      getList() {
          debug.log('ad getlist', this.trnsmis)
          this.$refs.advertisingList.getList();
      },
      getView(item) {
          debug.log('ad getView', this.trnsmis)
          if(this.trnsmis.trnsmisId == '') {
              this.showError({vc:this, error:'광고사를 선택하세요'})
              return
          }
          this.$refs.advertisingList.getView(item);
      },
      getUpdatePopup(item) {
          debug.log('getUpdatePopup', this.item)
          this.$refs.advertisingContent.getUpdatePopup(item);
      },
      getDeleteChk() {
          this.$refs.advertisingList.getDeleteChk();
      },
      getCnfirmChk() {
          this.$refs.advertisingList.getCnfirmChk();
      }
  },
  
};

</script>
