import axios from 'axios'

export default (url) => {

    if (process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'dev') {
        url = process.env.VUE_APP_API_URL + url
    }

    return axios.create({
        baseURL: url,
        withCredentials: true,
        timeout: 600000,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=UTF-8',
            "Access-Control-Allow-Origin": "*.infostock.co.kr"
        }
    })
}

