import Vue from 'vue'
import Vuex from 'vuex'

import { session } from './modules/common/session'
import { history } from './modules/common/history'
import { alert } from './modules/common/alert'
import { calendar } from './modules/common/calendar'
import { resource } from './modules/common/resource'
import { page } from './modules/common/page'

import { adminSchedule } from './modules/common/adminSchedule'

/* 주식신문 */
import { newspaper } from './modules/newspaper/newspaper'

/* 속보 */
import { flash } from './modules/flash/flash'

/* 특징 종목/테마 */

/* 시황 */
import { market_news } from './modules/market/market-news'

/* 종목 */
import { stock } from './modules/stock/stock'
import { stock_news } from './modules/stock/stock-news'
import { usa_stock_news } from './modules/stock/usa-stock-news'
import { stock_summary_extra } from './modules/stock/stock-summary-extra'
import { stock_discl } from './modules/stock/stock-discl'
import { stock_strong_buy } from './modules/stock/stock-strong-buy'
import { stock_new_recommend } from './modules/stock/stock-new-recommend'
import { stock_invest_opinion } from './modules/stock/stock-invest-opinion'

/* 테마 */
import { theme } from './modules/theme/theme'
import { theme_featured } from './modules/theme/theme-featured'
import { theme_news } from './modules/theme/theme-news'
import { theme_stock } from './modules/theme/theme-stock'

/* 스케쥴 */
import { schedule } from './modules/schedule/schedule'

/* 실적 */
import { earning } from './modules/earning/earning'
import { preview } from './modules/earning/preview'


/* 데이터 */

/* 관리 */
import { manage } from './modules/manage/manage'
import { member } from './modules/manage/member'

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        adminSchedule: adminSchedule,
        
        session: session,
        history: history,
        alert: alert,
        calendar: calendar,
        resource: resource,
        page: page,
        market_news: market_news,
//        market_us_theme: market_us_theme,
        stock: stock,
        stock_news: stock_news,
        usa_stock_news: usa_stock_news,
        stock_summary_extra: stock_summary_extra,
        stock_discl: stock_discl,
        stock_strong_buy: stock_strong_buy,
        stock_new_recommend: stock_new_recommend,
        stock_invest_opinion: stock_invest_opinion,

        theme: theme,
        theme_featured: theme_featured,
        theme_news: theme_news,
        theme_stock: theme_stock,

        schedule: schedule,

        earning: earning,
        preview: preview,

        flash: flash,

        member: member,
        newspaper : newspaper,
        manage: manage,

        // 22.02.28 한수예 (merge로 중복)
        // adminSchedule: adminSchedule,

    }
})